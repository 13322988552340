import React, { FunctionComponent } from 'react'
import { Link, GatsbyLinkProps } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Anchor: FunctionComponent<GatsbyLinkProps<any>> = ({ to, ref, ...other }) => {
  if (to.startsWith('http')) {
    return (
      <OutboundLink href={to} {...other} target="_blank" />
    )
  } else {
    return (
      <Link<any> to={to} {...other} />
    )
  }
}

export default Anchor
