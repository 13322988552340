import React, { FunctionComponent, Children } from 'react'
import styled from 'styled-components'

const Scene = styled.div`
  width: 210px;
  height: 190px;
  margin: 50px auto;
  perspective: 1000px;
`

const Carousel = styled.div<{ speed: number }>`
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateZ(-288px);
  transform-style: preserve-3d;
  transition: transform 1s;
  animation: move ${props => props.speed}s linear infinite;

  @keyframes move {
    from {
      transform: translateZ(-286px) rotateY(0deg) ;
    }

    to {
      transform: translateZ(-286px) rotateY(360deg) ;
    }
  }
`

const Cell = styled.div`
  position: absolute;
  width: 190px;
  // height: 120px;
  left: 10px;
  top: 10px;
  line-height: 116px;
  font-size: 80px;
  font-weight: bold;
  text-align: center;
  transition: transform 1s, opacity 1s;
`

const RotatingCarousel: FunctionComponent<{ speed: number }> = ({ children, speed }) => {
  const length = Children.count(children)
  const cells = Children.map(children, (child, index) => {
    return (
      <Cell
        key={index}
        style={{ transform: `rotateY(${360 / length * index}deg) translateZ(288px)` }}
      >
        {child}
      </Cell>
    )
  })

  return (
    <Scene>
      <Carousel speed={speed}>
        {cells}
      </Carousel>
    </Scene>
  )
}

export default RotatingCarousel
