import React, { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Section, { SectionData } from '../components/Section'

interface IndexPageTemplateProps {
  pathname: string
  sections: SectionData[]
}

export const IndexPageTemplate: FunctionComponent<IndexPageTemplateProps> = ({ sections, pathname }) => {
  return (
    <>
      {sections.map((section, index) => (
        <Section
          key={index}
          pathname={pathname}
          section={section}
        />
      ))}
    </>
  )
}

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        sections={frontmatter.sections}
        pathname={location.pathname}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const query = graphql`
  fragment IndexPostFragment on MarkdownRemark {
    frontmatter {
      sections {
        size
        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        fontColor
        alignment {
          vertical
        }
        columns {
          contents {
            type
            body
            fontColor
            fontSize
            alignment
            textWeight
            shadow
            speed

            # Image
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            # Tile list
            tiles {
              image {
                childImageSharp {
                  fluid(maxWidth: 1024, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              title
              description
            }
            tileSize

            # Chart
            chartType
            labels
            datasets {
              label
              color
              data
              unit
            }

            # Buttons
            buttons {
              label
              href
              intent
            }
          }
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...IndexPostFragment
    }
  }
`
