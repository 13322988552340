import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { CardList, Card, CardLink, CardImage, CardContent } from './card'
import Image from './PreviewCompatibleImage'
import { tablet } from './styles'

const Title = styled.h2.attrs({ className: 'title is-4' })`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem !important;

  @media ${tablet} {
    font-size: 15px !important;
    font-weight: normal;
  }
`

const Content = styled.div.attrs({ className: 'content' })`
  word-break: keep-all;
  text-align: center;

  @media ${tablet} {
    padding-top: 10px;
  }
`

interface Item {
  image: any
  title: ReactNode
  description: ReactNode
  to: string
}

interface TileListProps {
  items: Item[]
  tileSize: string
  limit?: number
}

export const TileList: FC<TileListProps> = ({ items, tileSize, limit }) => {
  const clippedItems = typeof limit === 'number' ? items.slice(0, limit) : items

  return (
    <CardList>
      {clippedItems.map(({ image, title, description, to }, index) => {
        const CardComponent = to ? CardLink : Card

        return (
          <div key={index} className={classNames('column', 'is-6-mobile', `is-${tileSize}-desktop`)}>
            <CardComponent to={to}>
              <CardImage>
                <Image imageInfo={{ image }} />
              </CardImage>
              <CardContent>
                <Title>
                  {title}
                </Title>
                <Content>
                  {description}
                </Content>
              </CardContent>
            </CardComponent>
          </div>
        )
      })}
    </CardList>
  )
}
