import styled from 'styled-components'
import Anchor from './Anchor'
import { tablet } from './styles'

export const CardList = styled.div.attrs({ className: 'columns is-mobile' })`
  flex-wrap: wrap;
`

export const CardWrapper = styled.div.attrs({ className: 'column is-3-desktop is-6-mobile' })`
`

export const Card = styled.div.attrs({ className: 'card' })`
  display: block;
  height: 100%;
  box-shadow: none !important;
`

export const CardLink = styled(Anchor).attrs({ className: 'card' })`
  display: block;
  height: 100%;
  box-shadow: none !important;

  :hover {
    background-color: #e2f1ff;
  }
`

export const CardImage = styled.div.attrs({ className: 'card-image' })`
  height: 200px;
  width: 180px;
  margin: auto;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  @media ${tablet} {
    height: 120px;
    width: 120px;
  }
`

export const CardContent = styled.div.attrs({ className: 'card-content' })`
  @media ${tablet} {
    padding: 0 !important;
    color: #666;
    font-size: 13px;
  }
`
