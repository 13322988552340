import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Anchor from './Anchor'
import RotatingCarousel from './RotatingCarousel';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const query = graphql`
  query ServiceRotatingCarouselQuery {
    allMarkdownRemark(
      sort: { order: [ASC, ASC], fields: [frontmatter___subsidiary, frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "service-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            redirectTo
            image {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ({ speed }) => {
  const data = useStaticQuery(query)
  if (!data.allMarkdownRemark) {
    return null
  }

  const { edges: posts } = data.allMarkdownRemark

  if (!posts) {
    return null
  }

  return (
    <RotatingCarousel speed={speed}>
      {posts.map(({ node: post }) => {
        const { redirectTo, image } = post.frontmatter

        return (
          <Anchor key={post.id} to={redirectTo || post.fields.slug}>
            <PreviewCompatibleImage imageInfo={{ image }} />
          </Anchor>
        )
      })}
    </RotatingCarousel>
  )
}
