import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { TileList } from './tile-list'

const query = graphql`
  query ServicePostRollQuery {
    allMarkdownRemark(
      sort: { order: [DESC, ASC, ASC], fields: [frontmatter___subsidiary, frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "service-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            redirectTo
            subsidiary
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const Tag = styled.span.attrs({ className: 'tag' as string })`
  margin-left: 5px;
`

export default ({ limit }) => {
  const data = useStaticQuery(query)
  if (!data.allMarkdownRemark) {
    return null
  }

  const { edges: posts } = data.allMarkdownRemark

  if (!posts) {
    return null
  }

  return (
    <TileList
      limit={limit}
      tileSize="3"
      items={posts.map(({ node: post }) => {
        const { image, title, description, redirectTo, subsidiary, investment } = post.frontmatter
        return {
          image,
          title: (
            <>
              {title}
              {subsidiary && <Tag className="is-info">자회사</Tag>}
              {investment && <Tag className="is-link">투자회사</Tag>}</>
          ),
          description,
          to: redirectTo,
        }
      })}
    />
  )
}
