import React, { FunctionComponent, useState } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Content from './Content'
import variables from './variables.scss'

export interface SectionData {
  size?: string
  backgroundImage?: any
  fontColor?: string
  alignment?: {
    vertical: string
  }
  columns: Array<{
    contents: Array<{
      type: string
      body?: string
      alignment?: string
      textWeight?: string
    }>
  }>
}

interface SectionProps {
  pathname: string
  section: SectionData
}

const HeroSection = styled.section`
  position: relative;
  overflow: hidden;

  &.in-viewport {
    b {
      color: ${variables.primary};
      transition-duration: 2.5s;
    }
  }
`

const Section: FunctionComponent<SectionProps> = ({ pathname, section }) => {
  const color = section.fontColor || 'white'
  const { alignment } = section
  const verticalAlignment = alignment && alignment.vertical
  const size = section.size || 'medium'

  if (!section.columns) {
    return null
  }

  return (
    <HeroSection className={classNames('hero', {
      'is-medium': size === 'medium',
      'is-large': size === 'large',
    })}>
      <div className="hero-body">
        <div className="container">
          <div className={classNames('columns', { 'is-vcentered': verticalAlignment === 'center' })}>
            {section.columns.map(({ contents }, index) => {
              return (
                <div key={index} className="column">
                  {contents && contents.map((content, index) => (
                    <Content key={index} color={color} pathname={pathname} {...content} />
                  ))}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <PreviewCompatibleImage
        imageInfo={{ image: section.backgroundImage }}
        style={{ borderRadius: 'none', position: 'absolute', minWidth: '100%', minHeight: '100%', zIndex: -1 }}
      />
    </HeroSection>
  )
}

export default Section
