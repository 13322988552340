import React, { FunctionComponent } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const LinkStyled = styled(Link)`
  color: #4a4a4a;
  display: block;
  padding: 15px;

  :hover {
    background-color: #e2f1ff;
  }
`

const query = graphql`
  query InterviewPostRollQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "interview-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            jobTitle
            description
            templateKey
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const Root = styled.div.attrs({ className: 'columns' })`
  flex-wrap: wrap;
`

const ImageContainer = styled.div`
  margin-bottom: 20px;
`

const Title = styled.h2.attrs({ className: 'subtitle' })`
  margin-top: 0px;
`

const Column = styled.div.attrs({ className: 'column is-4' })`
  margin-bottom: 20px;
`

interface InterviewPostRollProps {
  limit?: number
}

const InterviewPostRoll: FunctionComponent<InterviewPostRollProps> = ({ limit }) => {
  const data = useStaticQuery(query)
  if (!data.allMarkdownRemark) {
    return null
  }

  const { edges: posts } = data.allMarkdownRemark
  const clippedPosts = typeof limit === 'number' ? posts.slice(0, limit) : posts

  return (
    <Root>
      {clippedPosts.map(({ node: post }) => {
        const { image, title, jobTitle, description } = post.frontmatter

        return (
          <Column key={post.id}>
            <LinkStyled to={post.fields.slug}>
              <ImageContainer>
                {image && <PreviewCompatibleImage imageInfo={ image }/>}
              </ImageContainer>
              <h2 className="subtitle has-text-weight-semibold">
                {title}
              </h2>
              {description}
            </LinkStyled>
          </Column>
        )
      })}
    </Root>
  )
}

export default InterviewPostRoll
