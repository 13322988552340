import React, { FunctionComponent, useRef, useEffect } from 'react'
import Chart from 'chart.js'

interface ChartProps {
  type: string
  labels: string[]
  datasets: any
  fontColor: string
}

const UNITS = ['', '만', '억', '조', '경']
const SEPARATOR = 10000

function formatKRW(value: number, index = 0) {
  if (index >= UNITS.length) {
    return value
  }

  const mod = value % SEPARATOR
  const upper = value >= SEPARATOR
    ? formatKRW((value - mod) / SEPARATOR, index + 1)
    : ''

  return upper + (mod > 0 ? mod + UNITS[index] : '')
}

const ChartComponent: FunctionComponent<ChartProps> = ({ type, labels, datasets, fontColor }) => {
  if (!datasets) {
    return null
  }

  const canvas = useRef(null);

  useEffect(() => {
    const ctx = canvas.current.getContext('2d')
    const chart = new Chart(ctx, {
      type,
      data: {
        labels,
        datasets: datasets
          .filter(dataset => dataset.data)
          .map(dataset => ({
            label: dataset.label,
            backgroundColor: dataset.color,
            data: dataset.data.map(parseFloat),
          })),
      },
      options: {
        responsive: true,
				tooltips: {
					mode: 'index',
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = datasets[tooltipItem.datasetIndex]
              let label = data.datasets[tooltipItem.datasetIndex].label || ''
              if (label) {
                label += ': '
              }

              if (typeof tooltipItem.yLabel === 'string') {
                return label + tooltipItem.yLabel
              }

              return label + formatKRW(tooltipItem.yLabel) + (dataset.unit ? ` ${dataset.unit}` : '')
            },
          },
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontSize: 13,
              fontColor,
              // Include a dollar sign in the ticks
              callback: value => {
                return formatKRW(value)
              },
            },
          }],
          xAxes: [{
            ticks: {
              fontSize: 13,
              fontColor,
            },
          }],
        },
        legend: {
          labels: {
            fontSize: 13,
            fontColor,
            generateLabels: chart => {
              const labels = Chart.defaults.global.legend.labels.generateLabels(chart)
              for (let i = 0, len = datasets.length; i < len; i++) {
                const dataset = datasets[i]
                if (dataset.unit) {
                  labels[i].text += ` (${dataset.unit})`
                }
              }
              return labels
            }
          },
        },
      },
    })

    return () => {
      chart.destroy()
    }
  }, [type, labels, datasets])

  return (
    <canvas ref={canvas} />
  )
}

export default ChartComponent
