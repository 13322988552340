import React, { createElement, FunctionComponent } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import resolvePathname from 'resolve-pathname'
import Chart from '../components/Chart'
import ServicePostRoll from '../components/ServicePostRoll'
import JobPostRoll from '../components/JobPostRoll'
import InterviewPostRoll from '../components/InterviewPostRoll'
import ServiceRotatingCarousel from '../components/ServiceRotatingCarousel'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { TileList } from './tile-list'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import styled from 'styled-components'

const ImageWrapper = styled.div`
  margin: 2em 0;
`

interface ContentProps {
  pathname: string
  type: string
  tag?: string
  body?: string
  fontSize?: string
  shadow?: boolean
  alignment?: string
  textWeight?: string
  color?: string
  fontColor?: string
  buttons?: any[]
  speed?: number
  limit?: number

  chartType?: string
  labels?: string[]
  datasets?: any
  image?: any
  tiles?: any
  tileSize?: string
}

const Content: FunctionComponent<ContentProps> = props => {
  const { pathname, type, alignment, textWeight, color } = props

  switch (type) {
    case 'text': {
      const { shadow, body, fontColor } = props

      const tag = props.tag || 'p'
      const fontSize = parseInt(props.fontSize || '4', 10)

      return createElement(tag, {
        className: classNames('subtitle', {
          'is-1': fontSize === 1,
          'is-2': fontSize === 2,
          'is-3': fontSize === 3,
          'is-4': fontSize === 4,
          'is-5': fontSize === 5,
          'is-6': fontSize === 6,
          'is-7': fontSize === 7,
          'has-text-centered': alignment === 'center',
          'has-text-right': alignment === 'right',
          'has-text-weight-bold': textWeight === 'bold',
          'has-text-shadowed': shadow,
        }),
        dangerouslySetInnerHTML: { __html: body },
        style: {
          color: fontColor || color,
          wordBreak: 'keep-all',
          lineHeight: fontSize < 4 ? 1.5 : 1.8,
        },
      })
    }
    case 'image': {
      const { image, alignment } = props
      if (!image) {
        return null
      }

      return (
        <ImageWrapper className={classNames({
          'has-text-centered': alignment === 'center',
          'has-text-right': alignment === 'right',
        })}>
          <PreviewCompatibleImage
            imageInfo={{ image }}
          />
        </ImageWrapper>
      )
    }
    case 'chart': {
      const { chartType, labels, datasets } = props

      return (
        <Chart
          type={chartType}
          labels={labels}
          datasets={datasets}
          fontColor={color}
        />
      )
    }
    case 'service-rotating-carousel': {
      const { speed } = props
      return (
        <ServiceRotatingCarousel speed={speed} />
      )
    }
    case 'buttons': {
      const { buttons } = props
      if (!buttons) {
        return null
      }

      return (
        <div className={classNames('buttons', {
          'is-centered': alignment === 'center',
          'is-right': alignment === 'right',
        })}>
          {buttons.map((button, index) => {
            const className = classNames('button is-large', {
              'is-primary': button.intent === 'primary',
            })

            if (button.href && button.href.startsWith('http')) {
              return (
                <OutboundLink key={index} className={className} href={button.href} target="_blank">
                  {button.label}
                </OutboundLink>
              )
            } else {
              const to = resolvePathname(button.href, pathname)
              return (
                <Link key={index} className={className} to={to}>
                  {button.label}
                </Link>
              )
            }
          })}
        </div>
      )
    }
    case 'tile-list': {
      const { tiles, tileSize } = props
      return (
        <TileList items={tiles} tileSize={tileSize || '3'} />
      )
    }
    case 'service-list': {
      const { limit } = props
      return (
        <ServicePostRoll limit={limit} />
      )
    }
    case 'interview-posts': {
      const { limit } = props
      return (
        <InterviewPostRoll limit={limit} />
      )
    }
    case 'job-posts': {
      const { limit } = props
      return (
        <JobPostRoll limit={limit} />
      )
    }
    default: {
      return null
    }
  }
}

export default Content
