import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import classNames from 'classnames'
import { JobPostList } from './job-post-list'

const query = graphql`
  query JobPostRollQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "job-post" }, closed: { ne: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            category
            title
            description
            templateKey
            date(formatString: "MMMM DD, YYYY")
            service {
              frontmatter {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            tags
          }
        }
      }
    }
  }
`

const CLASS_SHOW_JOB_POSTS_LINK = 'show'

const JobPostsLink = styled.a.attrs({
  className: 'button is-primary is-rounded is-large' as string,
})`
  position: fixed !important;
  z-index: 100;
  bottom: 20px;

  transition: .35s;
  opacity: 0;

  &.${CLASS_SHOW_JOB_POSTS_LINK} {
    opacity: 1;
  }

  @media (max-width: 768px) {
    left: 50%;
    transform: translate(-50%, 0) scale(.7);

    &.${CLASS_SHOW_JOB_POSTS_LINK} {
      transform: translate(-50%, 0);
    }
  }

  @media (min-width: 769px) {
    right: 20px;
    transform: translate(0, 0) scale(.7);

    &.${CLASS_SHOW_JOB_POSTS_LINK} {
      transform: translate(0, 0);
    }
  }
`

enum Tab {
  DEVELOP = 'develop',
  DESIGN = 'design',
  MARKETING = 'marketing',
  MANAGE = 'manage',
}

export default ({ limit }) => {
  const [showJobPostsLink, setShowJobPostsLink] = useState(true)
  const [tab, setTab] = useState(Tab.DEVELOP)

  const data = useStaticQuery(query)
  if (!data.allMarkdownRemark) {
    return null
  }

  const { edges: posts } = data.allMarkdownRemark

  const clippedPosts = typeof limit === 'number' ? posts.slice(0, limit) : posts
  const finalPosts = clippedPosts
    .map(({ node }) => node)
    .filter(post => post.frontmatter.category === tab)

  const postCounts = {}
  for (const { node: post } of posts) {
    if (typeof postCounts[post.frontmatter.category] === 'number') {
      postCounts[post.frontmatter.category]++
    } else {
      postCounts[post.frontmatter.category] = 1
    }
  }

  const handleWaypointEnter = () => setShowJobPostsLink(false)
  const handleWaypointLeave = () => setShowJobPostsLink(true)

  return (
    <>
      <JobPostsLink className={showJobPostsLink ? CLASS_SHOW_JOB_POSTS_LINK : undefined} href="#jobPosts">
        채용 공고 확인하기
      </JobPostsLink>

      <a className="anchor" id={'jobPosts'} />
      <Waypoint
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      >
        <div>
          <div className="tabs is-toggle is-fullwidth">
            <ul>
              <li className={classNames({ 'is-active': tab === Tab.DEVELOP })}>
                <a onClick={() => setTab(Tab.DEVELOP)}>
                  <span>개발 ({postCounts[Tab.DEVELOP] || 0})</span>
                </a>
              </li>
              <li className={classNames({ 'is-active': tab === Tab.DESIGN })}>
                <a onClick={() => setTab(Tab.DESIGN)}>
                  <span>디자인 ({postCounts[Tab.DESIGN] || 0})</span>
                </a>
              </li>
              <li className={classNames({ 'is-active': tab === Tab.MARKETING })}>
                <a onClick={() => setTab(Tab.MARKETING)}>
                  <span>마케팅 ({postCounts[Tab.MARKETING] || 0})</span>
                </a>
              </li>
              <li className={classNames({ 'is-active': tab === Tab.MANAGE })}>
                <a onClick={() => setTab(Tab.MANAGE)}>
                  <span>관리 / 경영 ({postCounts[Tab.MANAGE] || 0})</span>
                </a>
              </li>
            </ul>
          </div>
          <JobPostList
            id="jobPosts"
            posts={finalPosts}
          />
        </div>
      </Waypoint>
    </>
  )
}
